import ElementUI from 'element-ui';

export default {
    baseURL: window._env.VUE_APP_BASEURL,
    headers: {
        'Content-Type': 'application/json'
    },
    tokenPosition: 'localStorage',
    tokenKey: 'Authorization',
    successCode: 200,
    mapCodeFunction: {
        401: (message) => {
            ElementUI.Message.closeAll();
            ElementUI.Message.error(message);
            window.location.replace('/login')
        },
        400: (message) => {
            ElementUI.Message.closeAll();
            ElementUI.Message.error(message);
        }
    },
    toast: (message) => {
        ElementUI.Message.closeAll();
        ElementUI.Message(message);
    },
    toastSuccess: (message) => {
        ElementUI.Message.closeAll();
        ElementUI.Message.success(message);
    },
    toastWarning: (message) => {
        ElementUI.Message.closeAll();
        ElementUI.Message.warning(message);
    },
    showloading: () => {
        window.loadingInstance = ElementUI.Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255,255, 255, 0.5)'
        })
        return window.loadingInstance
    },
    hiddenloading: () => {
        window.loadingInstance.close()
    },
    confirm: () => {
        return ElementUI.MessageBox.confirm("您正在执行删除操作，请再次确认！", "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })
    }
}