const parkSystemApi = {
    sysUserCaptcha: '/sysUser/captcha',//获取图片验证码
}
const parkParkApi = {
    sysUserLogin: '/callCenter/login',//用户登录
    sysUserlogout: '/callCenter/logout',//用户登出
    getOneServicer: '/callCenter/getOneServicer',//根据token或id获取用户信息
    selectWorkCountList: "/callCenter/selectWorkCountList",//导航栏数据统计
    insertCallCenterLog: '/callCenter/insertCallCenterLog',//来电记录日志
    getParkAreaList: '/parkArea/getParkAreaList',//加载停车场列表
    getAllPark: "/parkArea/getAllPark",//停车场
    selectCustomerInfo: '/callCenter/selectCustomerInfo',//查询来电客户信息
    selectFAQList: "/callCenter/selectFAQList",//查询FAQ话术
    insertFAQ: "/callCenter/insertFAQ",//添加FAQ话术
    getCouponInfo: "/callCenter/getCouponInfo",//查询所有优惠券
    compensationCoupon: "/callCenter/compensationCoupon",//补偿优惠券
    refundAbnormalOrderInfo: "/callCenter/refundAbnormalOrderInfo",//订单退款
    vehicleRelease: "/callCenter/vehicleRelease",//车辆放行

    insertReturnVisitOrder: '/callCenter/insertReturnVisitOrder',//添加回访工单
    dealReturnVisitOrderInfo: "/callCenter/dealReturnVisitOrderInfo",//处理工单
    selectHandleRecord: "/callCenter/selectHandleRecord",//工单处理记录
    selectReturnVisitOrderList: '/callCenter/selectReturnVisitOrderList',//待处理回访列表

    insertConsultation: "/callCenter/insertConsultation",//新增来电咨询记录
    selectHistoryConsultation: "/callCenter/selectHistoryConsultation",//咨询记录列表
    replyConsultation: "/callCenter/replyConsultation",//咨询文字回复
    selectConsultationReplyRecord: "/callCenter/selectConsultationReplyRecord",//咨询处理记录

    selectItemList: "/callCenter/selectItemList",//咨询类型

    insertComplaint: "/callCenter/insertComplaint",//新增来电投诉记录
    selectHistoryComplaint: "/callCenter/selectHistoryComplaint",//投诉记录列表
    handleComplaint: "/callCenter/handleComplaint",//添加投诉跟进
    selectComplaintHandleRecord: "/callCenter/selectComplaintHandleRecord",//投诉处理记录

    insertUrgeRecord: "/callCenter/insertCallCenterUrgeRecord",     //添加催收记录
    selectUrgeDetail: "/callCenter/selectCallCenterUrgeRecordByUserId",     //催收详情

    insertBrokenRecord: "/callCenter/insertBrokenRecord",//新增报修记录
    listCallBrokenRecord: "/callCenter/listCallBrokenRecord",//报修记录列表

    appointBrokenOrder: "/callCenter/appointBrokenOrder",//新增设备维修工单
    viewAllParkBrokenOrderInfo: "/callCenter/viewAllParkBrokenOrderInfo",//维修工单列表
    remindRepair: "/callCenter/remindRepair",//提醒维修

    insertDeviceRepair: "/callCenter/insertDeviceRepair",//新增设备巡检工单
    viewAllParkBrokenInfo: "/callCenter/viewAllParkBrokenInfo",//巡检工单列表
    remindPatrol: "/callCenter/remindPatrol",//提醒巡检

    addAbnormalOrderCallRecord: "/callCenter/addAbnormalOrderCallRecord",//新增异常订单记录
    selectAbnormalOrderProcess: "/callCenter/selectAbnormalOrderProcess",//查看异常订单处理流程

    notifyMessage: '/callCenter/notifyMessage',//发送消息
    selectEmpList: '/callCenter/selectEmpList',//查询工作人员列表

    selectLockSeatList: "/device/selectLockSeatList",//查询Lock列表
    openCloseLockById: "/device/openCloseLockById",//降锁
    selectChannelList: '/device/selectChannelList',//查询通道列表
    selfForensicsList: '/callCenter/selfForensicsList',//自主取证审核列表
    checkSelfForensics: '/callCenter/checkSelfForensics',//自主取证审核

    selectNoOrderSeatNum: '/callCenter/selectNoOrderSeatNum',//未产生订单的车位

    getDeviceList: '/monitor/getDeviceList',//设备列表
    getPlayAddress: '/monitor/getPlayAddress',//获取直播地址
    getDeviceChannel: '/monitor/getDeviceChannel',//获取通道信息
    getYingShiYunToken: '/monitor/getYingShiYunToken',//获取萤石Token
    startControl: '/monitor/startControl',//云台控制下发
    stopControl: '/monitor/stopControl',//云台控制取消
}
const parkServiceOrder = {
    getCurrentOrderList: "/userOrder/getCurrentOrderList",//当前停车
    selectUserAllOrderList: '/userOrder/selectUserAllOrderList',//查询来电客户停车订单信息
    selectOrderList: "/order/selectAllOrderList",//平台订单
    selectOrderDetail: "/order/selectOrderDetail",   // 通过查询订单详情
    payDetailList: "/order/selectOrderPayDetail",     //通过订单id获取支付信息
    selectAbnormalOrderList: "/order/selectAbnormalOrderList",   // 查询所有异常列表
    selectParkArrearsCarInfo: "/order/selectParkArrearsCarInfo",   // 平台待催收订单
    selectArrearsDetail: "/order/selectArrearsDetail", //欠款详情
    selectAppointmentOrderList: "/order/selectAppointmentOrderList",//平台预约订单
    getHaveEvidenceOrderList: "/order/getHaveEvidenceOrderList ",//平台已取证订单
    updateOrderToUnHaveEvidenceOrder: "/order/updateOrderToUnHaveEvidenceOrder",//重新取证
    selectArrearsOrPayOrderList: "/order/selectArrearsOrPayOrderList",  //查询欠费/补缴订单  1 欠费订单 2 补缴订单
    saveOrderOutLog: "/order/saveOrderOutLog",//人工订单离场
    selectPlateReportList: "/noPlate/selectPlateReportList",//无牌车审核列表
    updatePlateReport: "/noPlate/updatePlateReport",//无牌车审核
    addOvertimeRemark: "/order/addOvertimeRemark",//添加超时未录入订单备注
    updateOvertimeRemark: "/order/updateOvertimeRemark",//修改超时未录入订单备注
    queryOrderTimeDetail: '/pdaOrder/queryOrderTimeDetail',//订单时间线
}

const parkServiceUser = {
    // selectUserList: '/selectUserList',//用户列表
    carPlateBindingAuditList: "/parkingCar/carPlateBindingAuditList",//绑定申请记录列表
    carPlateBindingAudit: "/parkingCar/carPlateBindingAudit",//绑定申请处理
    selectCarAppealList: "/parkingCar/selectCarApprovalInfo",//申诉记录列表
    checkCarApprovalInfo: "/parkingCar/checkCarApprovalInfo",//申诉处理
}
const parkServiceParkLotMq = {
    openCloudGateByLocation: "/parkLot/openCloudGateByLocationForCallCenter",//道闸开闸
}
export default {
    install: (vue) => {
        for (const key in parkSystemApi) {
            if (parkSystemApi.hasOwnProperty(key)) {
                parkSystemApi[key] = "park-service-system" + parkSystemApi[key]
            }
        }
        for (const key in parkParkApi) {
            if (parkParkApi.hasOwnProperty(key)) {
                parkParkApi[key] = "park-service-park" + parkParkApi[key]
            }
        }
        for (const key in parkServiceOrder) {
            if (parkServiceOrder.hasOwnProperty(key)) {
                parkServiceOrder[key] = "park-service-order" + parkServiceOrder[key]
            }
        }
        for (const key in parkServiceUser) {
            if (parkServiceUser.hasOwnProperty(key)) {
                parkServiceUser[key] = "park-service-user" + parkServiceUser[key]
            }
        }
        for (const key in parkServiceParkLotMq) {
            if (parkServiceParkLotMq.hasOwnProperty(key)) {
                parkServiceParkLotMq[key] = "park-service-park-lot-mq" + parkServiceParkLotMq[key]
            }
        }
        vue.prototype.$api = { ...parkSystemApi, ...parkParkApi, ...parkServiceOrder, ...parkServiceUser, ...parkServiceParkLotMq }
    }
}